import React from "react";
import LeaveManagement from "../../component/user/LeaveManagement";

function UserProfile() {
  return (
    <div>
      <LeaveManagement />
    </div>
  );
}

export default UserProfile;
